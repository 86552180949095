import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider, background, extendTheme } from "@chakra-ui/react";
import App from "./App";
import "@fontsource/poppins";
import { GrAnalytics } from "react-icons/gr";

const theme = extendTheme({
  fonts: {
    heading: `poppins`,
    body: `poppins`,
  },
  styles: {
    global: {
      body: {
        background: "gray.50", 
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
