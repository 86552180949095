import { Box, Image, Text, Button, Heading } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import FingerLakesGold from "../products/fingerLakesGold";
import Chevre from "../products/chevre";
import FingerLakesGoldReserve from "../products/fingerLakesGoldReserve";
import CayugaBlue from "../products/cayugaBlue";
import Feta from "../products/feta";

const ProductPage = () => {
  return (
    <Box m={1}>
      <Navbar />
      <Box p="6">
        <Heading align={"center"} my={3}>
          Goat's Cheese
        </Heading>
        <FingerLakesGold />
        <br />
        <FingerLakesGoldReserve />
        <br />
        <Chevre />
        <br />

        <CayugaBlue />
        <br />
        <Feta />
      </Box>
    </Box>
  );
};

export default ProductPage;
