import {
  Box,
  Button,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useColorModeValue,
  Container,
  Grid,
  GridItem,
  Flex,
  Center,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

const options = [
  { id: 1, desc: "1 lorem ipsum" },
  { id: 2, desc: "Lorem, ipsum dolor." },
  { id: 3, desc: "Monthly Updates" },
];

const ThreeTierPricingHorizontal = () => {
  return (
    <Box py={6} width="full">
      <Stack spacing={4} width={"100%"} direction={"column"}>
        <Stack
          p={5}
          alignItems={"center"}
          justifyContent={{
            base: "flex-start",
            md: "space-around",
          }}
          direction={{
            base: "column",
            md: "row",
          }}
        >
          <Stack
            width={{
              base: "100%",
              md: "40%",
            }}
          >
            <Heading size={"xl"}>For Buyers Clubs Coordinators</Heading>
          </Stack>
          <Stack
            width={{
              base: "100%",
              md: "60%",
            }}
          >
            <Text textAlign={"left"}>
              At Sincero Distribution, we proudly collaborate with buyers&#39;
              clubs, local groups formed by individuals or organizations such as
              churches, community centers, and similar institutions. If you are
              part of a buyers&#39; club, or if you would like to create one and
              wish to place an order with us, please follow these steps:
            </Text>
          </Stack>
        </Stack>

        <SimpleGrid spacing={4} columns={{ base: 1, sm: 2, md: 3, lg: 4 }}>
          <Card border={"2px"} borderColor={"#508B96"}>
            <CardHeader>
              <Heading size="xl">1</Heading>
            </CardHeader>
            <CardBody mt={"-30px"}>
              <Heading size="md" mb={2}>
                View the Price List
              </Heading>
              <Text>
                Visit the “Products” tab on our website for cheese details or
                access the price list by using this
                <a
                  href="https://drive.google.com/file/d/16M_WzMNjbCJBQvyV4wU9K0tnGpuJDMIZ/view?usp=sharing
"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <b>link</b>
                </a>{" "}
              </Text>
            </CardBody>
          </Card>
          <Card border={"2px"} borderColor={"#508B96"}>
            <CardHeader>
              <Heading size="xl">2</Heading>
            </CardHeader>
            <CardBody mt={"-30px"}>
              <Heading size="md" mb={2}>
                Collect orders
              </Heading>
              <Text>
                Review the price list and collect orders from your members. You
                may use your preferred method of record-keeping or our template
                to create a master list.
              </Text>
            </CardBody>
          </Card>
          <Card border={"2px"} borderColor={"#508B96"}>
            <CardHeader>
              <Heading size="xl">3</Heading>
            </CardHeader>
            <CardBody mt={"-30px"}>
              <Heading size="md" mb={2}>
                Place your order
              </Heading>
              <Text>
                Please place your order using the “Cheese for Ukraine” Order
                Form and provide your order and shipping details. You can also
                place an order by emailing us at orders@sincerodistribution.com
              </Text>
            </CardBody>
          </Card>
          <Card border={"2px"} borderColor={"#508B96"}>
            <CardHeader>
              <Heading size="xl">4</Heading>
            </CardHeader>
            <CardBody mt={"-30px"}>
              <Heading size="md" mb={2}>
                Payment
              </Heading>
              <Text>
                To process your payment, please select your preferred payment
                method in the “Cheese for Ukraine” Order Form. If you have any
                questions regarding payment processing, feel free to reach out
                to us at orders@sincerodistribution.com or (929) 698-4455
              </Text>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

export default ThreeTierPricingHorizontal;
