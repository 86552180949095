import React from "react";
import logo from "../assets/sincero-rectanglewhite.png";

import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  useColorModeValue,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        // bgGradient={"linear(to-b, gray.400, gray.50)"}
        bgColor={"white"}
        color={"black"}
        minH={"140px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Link href="/">
            <Image
              src={logo}
              alt="Logo"
              htmlWidth="270" // Set the width as needed
              htmlHeight="auto" // Adjust the height as needed
              objectFit="contain" // Adjust how the image fits within its box
            />
          </Link>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("#4F8C97", "#4F8C97");
  const linkHoverColor = useColorModeValue("#52683B", "#52683B");
  const popoverContentBgColor = useColorModeValue("gray.100", "gray.100");

  return (
    <Stack direction={"row"} spacing={4} align={"center"}>
      <Popover trigger={"hover"} placement={"bottom-start"}>
        <PopoverTrigger>
          <Text
            p={2}
            fontWeight={"bold"}
            color={linkColor}
            _hover={{
              textDecoration: "none",
              color: linkHoverColor,
            }}
            style={{ fontSize: "1rem" }}
          >
            About <Icon as={ChevronDownIcon} />
          </Text>
        </PopoverTrigger>

        <PopoverContent
          border={0}
          boxShadow={"xl"}
          bg={popoverContentBgColor}
          p={4}
          rounded={"xl"}
          minW={"sm"}
        >
          <Stack>
            <Link href={"/mission-and-values"}>Mission and Values</Link>
            <Link href={"/cheese-for-ukraine"}>Cheese for Ukraine Project</Link>
          </Stack>
        </PopoverContent>
      </Popover>

      <Popover trigger={"hover"} placement={"bottom-start"}>
        <PopoverTrigger>
          <Text
            p={2}
            fontWeight={"bold"}
            color={linkColor}
            _hover={{
              textDecoration: "none",
              color: linkHoverColor,
            }}
            style={{ fontSize: "1rem" }}
          >
            Products <Icon as={ChevronDownIcon} />
          </Text>
        </PopoverTrigger>

        <PopoverContent
          border={0}
          boxShadow={"xl"}
          bg={popoverContentBgColor}
          p={4}
          rounded={"xl"}
          minW={"sm"}
        >
          <Stack>
            <Link href={"/cow-cheese"}>Cow Cheese</Link>
            <Link href={"/goat-cheese"}>Goat Cheese</Link>
          </Stack>
        </PopoverContent>
      </Popover>

      <Button
        color="#4F8C97"
        as={Link}
        p={2}
        href={"/supporters"}
        variant={"link"}
        _hover={{ textDecoration: "none", color: linkHoverColor }}
      >
        Supporters
      </Button>
      <Button
        color="#4F8C97"
        as={Link}
        p={2}
        href={"/order"}
        variant={"link"}
        _hover={{ textDecoration: "none", color: linkHoverColor }}
      >
        Order
      </Button>
      <Button
        color="#4F8C97"
        p={2}
        as={Link}
        href={"/contact"}
        variant={"link"}
        _hover={{ textDecoration: "none", color: linkHoverColor }}
      >
        Contact
      </Button>
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      <MobileNavItem label="About" children={AboutSubNav()} />
      <MobileNavItem label="Supporters" href={"/supporters"} />
      <MobileNavItem label="Contact" href={"/contact"} />
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "/"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={600} style={{ fontSize: "1rem" }}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const AboutSubNav = () => {
  return (
    <Stack>
      <Link href={"/mission-and-values"}>Mission and Values</Link>
      <Link href={"/cheese-for-ukraine"}>Cheese for Ukraine Project</Link>
    </Stack>
  );
};
