import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Link,
  Stack,
  Center,
  Container,
  Image,
} from "@chakra-ui/react";
import logo from "../assets/livelyrun.png";
import { useNavigate } from "react-router-dom";

const ContributorsHome = () => {
  let navigate = useNavigate();

  return (
    <Box p={8} alignItems={"center"} bgColor={"#20292C"} maxH="sm">
      <Heading
        mb={4}
        align="Center"
        as="h1"
        size="xl"
        bgClip="text"
        bgGradient="linear(to-l, gray.100, gray.200, gray.100)"
      >
        Our Contributors
      </Heading>
      <Box my={10}>
        <Center>
          <Stack align={"center"}>
            <Image
              borderRadius={"50%"}
              src={logo}
              alt="Logo"
              htmlWidth="150"
              htmlHeight="auto"
              mx={5}
              my={3}
            />
            <Heading size="lg" color={"white"} fontWeight={"normal"}>
              Lively Run Dairy
            </Heading>
            <Button
              color="white"
              _hover={{ bg: "gray.400" }}
              variant="outline"
              size="md"
              m={"3"}
              as={Link}
              href={"/supporters"}
            >
              Join us
            </Button>
          </Stack>
        </Center>
      </Box>
    </Box>
  );
};

export default ContributorsHome;
