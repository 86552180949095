import React from "react";
import {
  Box,
  Grid,
  Image,
  AspectRatio,
  Heading,
  Text,
  Center,
  Stack,
  Divider,
} from "@chakra-ui/react";
import TextSection from "../components/TextSection";
import Navbar from "../components/Navbar";
import Buyersclub from "../components/Buyersclub.js";
import Buyersclub2 from "../components/Buyersclub2.js";

function Order() {
  return (
    <Box>
      <Navbar />
      <Box m={10} px={10}>
        <Stack
          spacing={4}
          width={"100%"}
          direction={"column"}
          color={"white"}
          bgColor={"#4F8C97"}
          borderRadius={"md"}
        >
          <Stack
            p={5}
            alignItems={"center"}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            direction={{
              base: "column",
              md: "row",
            }}
          >
            <Stack
              width={{
                base: "100%",
                md: "40%",
              }}
            >
              <Heading size={"xl"}>For Businesses</Heading>
            </Stack>
            <Stack
              width={{
                base: "100%",
                md: "60%",
              }}
            >
              <Text textAlign={"left"}>
                We are proud to collaborate with businesses and non-profit
                organizations, including co-ops, grocery stores, food markets
                and restaurants. If you represent a business or a non-profit
                entity and would like to place an order with us, you can do so
                by emailing us at{" "}
                <b>
                  <a href="mailto:orders@sincerodistribution.com">
                    orders@sincerodistribution.com
                  </a>
                </b>
                .
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <Divider />

        <Buyersclub />
        <Buyersclub2 />
        <Center>
          <Box m={5} p={10} align={"center"}>
            <Heading size="lg" my={1} color={"#508B96"}>
              Thank you for being a part of “Cheese for Ukraine” project!
            </Heading>
            <Box maxW="2xl" textAlign={"center"}>
              <Text size="lg" my={1} fontStyle={"italic"}>
                If you have any questions or need further assistance, please
                contact us at{" "}
                <a href="mailto:orders@sincerodistribution.com">
                  orders@sincerodistribution.com
                </a>
              </Text>
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
}

export default Order;
