import React from "react";
import Hero from "../components/Hero/Hero";

import Navbar from "../components/NavbarHome/NavbarHome";
import SideHero from "../components/SideHero";

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <SideHero />
      
    </div>
  );
}

export default Home;
