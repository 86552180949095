import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const TextSection = ({ title, children }) => {
  return (
    <Box p={{ base: 4, md: 8 }} my={4} mx="auto" maxWidth="4xl">
      <Heading as="h2" size="xl" mb={4} fontWeight="bold">
        {title}
      </Heading>
      <Text fontSize="md" lineHeight="tall">
        {children}
      </Text>
    </Box>
  );
};

export default TextSection;
