import React from "react";
import { Box, Grid, Image, Heading, Text, Center } from "@chakra-ui/react";
import TextSection from "../components/TextSection";
import Navbar from "../components/Navbar";
import Supporter1 from "../supporters/supporter1"

function Supporters() {
  return (
    <Box>
      <Navbar />
      <Center>
        <Heading py={"2%"}>Collaborator Profiles</Heading>
      </Center>
      <Supporter1 />
    </Box>
  );
}

export default Supporters;
