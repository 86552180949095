"use client";

import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
  Center,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { ReactNode } from "react";
import logo from "../assets/sincero-rectangletransparent.png";
export default function SmallCentered() {
  return (
    <Box
      bg="#282828"
      color={useColorModeValue("gray.200", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Image src={logo} alt="Logo" htmlWidth={"200"} />
        <Stack direction={"row"} spacing={6}>
          <Box as="a" href={"/"}>
            Home
          </Box>
          <Box as="a" href={"/mission-and-values"}>
            Mission and Values
          </Box>
          <Box as="a" href={"/cow-cheese"}>
            Cow Cheese
          </Box>
          <Box as="a" href={"/goat-cheese"}>
            Goat Cheese
          </Box>
          <Box as="a" href={"/order"}>
            Order
          </Box>
          <Box as="a" href={"/contact"}>
            Contact
          </Box>
        </Stack>
      </Container>
      <Center>
        <Box
          borderTopWidth={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          p={3}
        >
          <Text>© 2024 Sincero Distributions. All rights reserved</Text>
        </Box>
      </Center>
    </Box>
  );
}
