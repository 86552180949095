import { Box, Image, Text, Button, Heading } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Shire from "../products/shire";
import Sheldrake from "../products/sheldrake";
import BlueYonder from "../products/blueYonder";
import LakeEffect from "../products/lakeEffect";

const ProductPage = () => {
  return (
    <Box m={1}>
      <Navbar />
      <Box p="6">
      <Heading  align={"center"} my={3}>Cow's Cheese</Heading>
        <Shire />
        <br />
        <Sheldrake />
        <br />
        <BlueYonder />
        <br />
        <LakeEffect />
      </Box>
    </Box>
  );
};

export default ProductPage;
