import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Center,
  Container,
  Image,
  Link,
} from "@chakra-ui/react";
import logo from "../assets/cfulogo.png";

const CheeseForUkraineHome = () => {
  return (
    <Box p={8} alignItems={"center"} bgColor={"#232C25"} maxH="sm">
      <Heading
        mb={4}
        as="h1"
        size="xl"
        align="Center"
        bgClip="text"
        bgGradient="linear(to-l, gray.100, gray.200, gray.100)"
        // color={"white"}
      >
        Our Projects
      </Heading>
      <Box my={10}>
        <Center>
          <Stack align={"center"}>
            <Image
              src={logo}
              alt="Logo"
              htmlWidth="120"
              htmlHeight="auto"
              mx={5}
            />
            <Heading size="lg" color={"white"} fontWeight={"normal"}>
              Cheese For Ukraine
            </Heading>
            <Button
              color="white"
              _hover={{ bg: "gray.400" }}
              variant="outline"
              size="md"
              m={"3"}
              as={Link}
              href={"/cheese-for-ukraine"}
            >
              Learn More
            </Button>
          </Stack>
        </Center>
      </Box>
    </Box>
  );
};

export default CheeseForUkraineHome;
