import React from "react";
import {
  Box,
  Grid,
  Image,
  AspectRatio,
  Heading,
  Text,
  Center,
} from "@chakra-ui/react";
import TextSection from "../components/TextSection";
import Navbar from "../components/Navbar";
import history_image1 from "../assets/history_1.jpg";
import history_image2 from "../assets/history_2.jpg";

const missionText =
  "Sincero Distribution is dedicated to distributing food products while actively supporting projects that provide help to refugees. By donating a percentage of profits to these initiatives, our goal is to provide help and support to the refugees. Our core value is to provide our society an opportunity to help those in need, while fostering a community that actively contributes to improving their lives.";
const historyText =
  "Mariia Tkach is the founder of Sincero Distribution. She is Ukrainian and pursued her undergraduate degree in Economics at The College of Wooster in Wooster, Ohio, in the United States. Prior to college, she participated in the Future Leaders Exchange Program (FLEX) and came to the US as an exchange student to share Ukrainian culture with American communities. In 2023, she became a part of the Global Democracy Ambassador Scholarship Program through which she has organized fundraisers on her college campus in support of Ukraine. Mariia had the chance to meet the owners of Lively Run Dairy Farm from New York State, who have played an active role in the area by hosting refugees and providing community support during the COVID-19 pandemic. Sharing common values, they initiated a collaborative project called 'Cheese for Ukraine'; which is the first project Sincero Distribution is donating a percentage of profits to.";
function Mission() {
  return (
    <Box>
      <Navbar />
      <Box m={10}>
        <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr" }} gap={5}>
          <TextSection title={"Our Mission"} children={missionText} />
          <AspectRatio ratio={16 / 9}>
            <iframe
              width="966"
              height="543"
              src="https://www.youtube.com/embed/ZiHeXLKqZyM"
              title="The Art of Cheesemaking"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>


          </AspectRatio>
        </Grid>
        <Box
          borderRadius={"lg"}
          boxShadow={"lg"}
          p={10}
          maxW={"3xl"}
          mx={"auto"}
          my={10}
        >
          <Heading mb={4} align={"center"}>
            Why Sincero Distribution?
          </Heading>
          <Text align={"center"}>
            The choice of the company name, “Sincero”, reflects our commitment
            to our stakeholders and our core value of sincerity. We believe that
            when individuals and organizations act with sincerity, they can
            unite people from diverse backgrounds and perspectives towards a
            common purpose. We strive to build trust and authenticity in all our
            relationships: with customers, partners, and the communities we
            serve.
          </Text>
        </Box>
        <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr 3fr" }} gap={5}>
          <Image minW ="sm" rounded={"md"} src={history_image1} />
          <Image minW="xs" rounded={"md"} src={history_image2} />
          <TextSection title={"Our History"} children={historyText} />
        </Grid>
      </Box>
    </Box>
  );
}

export default Mission;
