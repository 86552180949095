import React from "react";
import CheeseForUkraineHome from "./CheeseForUkraineHome";
import ContributorsHome from "./ContributorsHome";
import { Box, HStack, SimpleGrid } from "@chakra-ui/react";

const SideHero = () => {
return (
    <Box>
        <SimpleGrid columns={{ base: 1, md: 2 }}>
            <CheeseForUkraineHome />
            <ContributorsHome />
        </SimpleGrid>
    </Box>
);
};

export default SideHero;
