import Navbar from "../components/Navbar";
import ContactCollage from "../assets/ContactCollage.png";

import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1.5fr" }}
        p={10}
        alignItems="center"
        m={"8%"}
        mt={"6%"}
        bgColor={"white"}
        borderRadius={"xl"}
        boxShadow={"lg"}
      >
        {/* Contact Information Section */}
        <GridItem>
          <VStack align="flex-end" spacing={4} p={6}>
            <Heading size="xl" color="teal.600">
              Contact Us
            </Heading>
            <Text fontSize="md">
              <strong>Email:</strong> orders@sincerodistribution.com
            </Text>
            <Text fontSize="md">
              <strong>Phone:</strong> +1 (929) 698-4455
            </Text>
            <Text fontSize="md">
              <strong>Location:</strong> NYC, USA
            </Text>
          </VStack>
        </GridItem>
        <GridItem>
          <Image
            src={ContactCollage} // Replace with your image URL
            alt="Contact Image"
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default ContactPage;
