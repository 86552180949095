import {
  Box,
  Button,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useColorModeValue,
  Container,
  Grid,
  GridItem,
  Flex,
  Center,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

const options = [
  { id: 1, desc: "1 lorem ipsum" },
  { id: 2, desc: "Lorem, ipsum dolor." },
  { id: 3, desc: "Monthly Updates" },
];

const ThreeTierPricingHorizontal = () => {
  return (
    <Box py={6} width="full">
      <Stack spacing={4} width={"100%"} direction={"column"}>
        <Stack
          p={5}
          alignItems={"center"}
          justifyContent={{
            base: "flex-start",
            md: "space-around",
          }}
          direction={{
            base: "column",
            md: "row",
          }}
        >
          <Stack
            width={{
              base: "100%",
              md: "40%",
            }}
          >
            <Heading size={"xl"}>For Buying Club Members</Heading>
          </Stack>
          <Stack
            width={{
              base: "100%",
              md: "60%",
            }}
          ></Stack>
        </Stack>

        <Divider />
        <SimpleGrid spacing={4} columns={{ base: 1, sm: 2, md: 3, lg: 4 }}>
          <Card border={"2px"} borderColor={"#52683B"}>
            <CardHeader>
              <Heading size="xl">1</Heading>
            </CardHeader>
            <CardBody mt={"-30px"}>
              <Heading size="md" mb={2}>
                View the Price List
              </Heading>
              <Text>
                Visit the “Products” tab on our website for cheese details or
                access the price list by using this
                <a
                  href="https://drive.google.com/file/d/16M_WzMNjbCJBQvyV4wU9K0tnGpuJDMIZ/view?usp=sharing
"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <b>link</b>
                </a>{" "}
              </Text>
            </CardBody>
          </Card>
          <Card border={"2px"} borderColor={"#52683B"}>
            <CardHeader>
              <Heading size="xl">2</Heading>
            </CardHeader>
            <CardBody mt={"-30px"}>
              <Heading size="md" mb={2}>
                Submit Order Details
              </Heading>
              <Text>
                Review the price list and inform the order coordinators at your
                organization of your chosen items. They will then place an order
                with Sincero Distribution.
              </Text>
            </CardBody>
          </Card>
          <Card border={"2px"} borderColor={"#52683B"}>
            <CardHeader>
              <Heading size="xl">3</Heading>
            </CardHeader>
            <CardBody mt={"-30px"}>
              <Heading size="md" mb={2}>
                Payment{" "}
              </Heading>
              <Text>
                To process your payment, please consult with the order
                coordinators at your organization. They will submit a single
                payment to Sincero Distribution upon placing the order.
              </Text>
            </CardBody>
          </Card>
          <Card border={"2px"} borderColor={"#52683B"}>
            <CardHeader>
              <Heading size="xl">4</Heading>
            </CardHeader>
            <CardBody mt={"-30px"}>
              <Heading size="md" mb={2}>
                Pickup and Enjoy
              </Heading>
              <Text>
                Once the products arrive at the designated pickup location,
                please coordinate with your order coordinators to arrange a
                consistent method for distribution. You may either pick up your
                order directly from your organization or arrange for someone
                else to assist with pickup or delivery to your location.
              </Text>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

export default ThreeTierPricingHorizontal;
