import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import image from "./product_images/cheddar.png";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container maxW={"5xl"} py={10} boxShadow={"lg"} borderRadius={"lg"}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Heading>Lake Effect Cheddar</Heading>
          <Text
            textTransform={"uppercase"}
            color={"black"}
            fontWeight={600}
            fontSize={"sm"}
            bgGradient="linear(to-l, gray.300, gray.100, gray.200, gray.100)"
            p={3}
            alignSelf={"flex-start"}
            rounded={"lg"}
          >
Pasteurized New York-Style Cow’s Milk Cheddar          </Text>

          <Text color={"gray.500"} fontSize={"lg"}>
            Aged for at least six months, Lake Effect Cheddar has a creamy
            texture that showcases the milk it is made from. This Cheddar's
            sharpness is offset by a subtle underlying nutty sweetness. Its bold
            flavor makes it a great featured ingredient for dishes like cheddar
            biscuits or cheddar-broccoli soup.
          </Text>
          <Stack
            spacing={2}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature text={"Shelf Life: NA"} />
            <Feature text={"Aged: at least 6 months"} />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={image}
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
