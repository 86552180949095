import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import MissionAndValues from "./pages/MissionAndValues";
import CheeseForUkraine from "./pages/CheeseForUkraine";
import Supporters from "./pages/Supporters";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import CowProducts from "./pages/CowProducts";
import GoatProducts from "./pages/GoatProducts";
import Order from "./pages/HowToOrder";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mission-and-values" element={<MissionAndValues />} />
        <Route path="/cheese-for-ukraine" element={<CheeseForUkraine />} />
        <Route path="/supporters" element={<Supporters />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cow-cheese" element={<CowProducts />} />
        <Route path="/goat-cheese" element={<GoatProducts />} />
        <Route path="/order" element={<Order />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
