import React from "react";
import {
  Box,
  Grid,
  Image,
  AspectRatio,
  Heading,
  Text,
  Stack,
} from "@chakra-ui/react";
import TextSection from "../components/TextSection";
import Navbar from "../components/Navbar";
import logo from "../assets/cfulogo.png";
import image from "../assets/cheese_for_ukraine.jpg";

const about =
  "'Cheese for Ukraine', a collaborative project initiative between Sincero Distribution and Lively Run Dairy Farm, is driven by our shared mission to making a meaningful difference in the lives of Ukrainian refugees. Our project seeks to provide tangible assistance to those most affected by the ongoing crisis, embodying our value of international cooperation. Lively Run Dairy plays an important role in the project by providing cheese for distribution, while Sincero Distribution undertakes the distribution of food products and donates a percentage of our profits to the 'Cheese for Ukraine' Project fund.";

function Mission() {
  return (
    <Box>
      <Navbar />
      <Box m={10}>
        <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr" }} gap={1}>
          <Stack>
            <Image
              src={logo}
              alt="Logo"
              htmlWidth="170"
              htmlHeight="auto"
              mx={5}
              mb={"-60px"}
            />
            <Box maxWidth="4xl" mt={"30px"}>
              {/* <TextSection title={"Why it Matters?"} children={about} /> */}
              <TextSection title={"Cheese For Ukraine"} children={about} />
            </Box>
          </Stack>
          <Image
          maxWidth={"100%"}
              rounded={"md"}
              alt={"feature image"}
              src={image}
              objectFit={"cover"}
            />
        </Grid>
        <Heading size={"xl"} align={"center"} p={10} m={5}>
          Join us in our efforts to make a positive difference in the lives of
          those affected by the crisis!
        </Heading>
      </Box>
    </Box>
  );
}

export default Mission;
