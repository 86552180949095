import React from "react";
import { Box, Heading, Text, Button, Stack, Center } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

const Hero = () => {
  let navigate = useNavigate();

  return (
    <div className="hero-container">
      <Box>
        <Heading
          my={"10%"}
          maxW="1000px"
          as="h1"
          size="3xl"
          bgClip="text"
          bgGradient="linear(to-l, gray.500, gray.100, gray.400)"
        >
          Sincero: Your Partner in Purposeful Distribution
        </Heading>
        <Box align={"center"} mt={10}>
          <Text fontSize="3xl" mb={8} color={"white"} as={"b"}>
            Our mission
          </Text>
          <Text fontSize="xl" mb={3} color={"white"} maxW={"590px"}>
            Sincero Distribution is dedicated to distributing food products
            while actively supporting projects that provide help to refugees.
            
          </Text>
        </Box>
        <Center>
          {/* <Button
              colorScheme="gray"
              onClick={() => navigate("/mission-and-values")}
            >
              Our Products
            </Button> */}
          <Button
            colorScheme="gray"
            variant="outline"
            textColor={"white"}
            onClick={() => navigate("/mission-and-values")}
          >
            Learn More
          </Button>
        </Center>
      </Box>
    </div>
  );
};

export default Hero;
