import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  Box,
  Divider,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import image from "./supporter_images/livelyRunDairy.png";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container
      maxW={"5xl"}
      py={10}
      boxShadow={"lg"}
      borderRadius={"lg"}
      mb={10}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Heading>Lively Run Dairy</Heading>
          <Stack spacing={2}>
            <Feature text={"Location: New York"} />
            <Feature text={"Farm Type: Family Owned"} />
          </Stack>
          <Divider />
          <Text color={"gray.700"}>
            Lively Run Dairy is one of the longest operating commercial goat
            dairies in the country, beginning production in 1982 in Interlaken,
            NY. They are pioneers of goat cheese in the United States domestic
            market.
            <br />
            Their mission is to craft delectable artisan cheese inspired by the
            historic Finger Lakes food culture in a way that honors people,
            animals, and the land. They aim to empower their employees,
            customers, partners, and the greater community to live healthier,
            happier lives.
            <br />
            Lively Run Dairy is a family-owned and operated creamery located in
            the Finger Lakes Region of NY. Steve and Susanne Messmer, and their
            two sons, Pete and Dave Messmer, own Lively Run Dairy.
          </Text>
        </Stack>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={image}
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
      <Box my={7} py={5}>
        <Heading my={1}>Sustainable Milk Supply</Heading>
        <Text color={"gray.700"}>
          {" "}
          Lively Run Dairy partners with local New York dairy farmers who
          practice high quality animal care and operate with a sound animal
          welfare program. They strive to pay their farmers a fair price for
          their milk so that they can grow and thrive alongside us.
        </Text>
      </Box>
     
      <Box>
        <Heading my={1}>Lively Run Dairy Refugee Work History</Heading>
        <Text color={"gray.700"}>
          {" "}
          Steve and Susanne Messmer and their family began operating Lively Run
          Dairy in 1995. However, their involvement with refugee relief work
          began years before in Germany where they founded a volunteer
          organization to serve the needs of refugees and economic migrants from
          many countries who were seeking asylum in Germany. The refugees were
          fleeing war and/or persecution for religious and political reasons or
          were relocating due to extreme economic hardship. When the Messmers
          relocated to the United States in 1992, their vision was to start a
          farmstead cheese-making operation capable of hosting refugees and
          economic migrants on the farm. A place where they could be welcomed
          and supported emotionally, socially, and spiritually, and learn
          language and cultural skills to assist them in a permanent transition
          into their new homeland. Over the intervening years, the Messmers have
          hosted refugee families from abroad, economic migrants, and people
          from our own community in states of trauma and healing during life
          transitions, as well as numerous interns and curious individuals
          seeking to learn more about farmstead and cheese-making life. Most
          recently, Lively Run has hosted refugees from Ukraine and provided
          friendship and social interactions with others already in our
          community. Lively Run, in partnership with Sincero Distribution Inc.
          now seeks to launch a new initiative called, Cheese for Ukraine.
          Lively Run will provide cheese to Sincero Distribution Inc. who will
          sell it directly to consumers, or to stores and restaurants. A portion
          of the profit of cheese sales will be dedicated to a fund to provide
          support to Ukrainian refugees domestically or abroad.
        </Text>
      </Box>
    </Container>
  );
}
